const config = {
  darkMode: true,
  RTL: false,
  brandedMenu: false,
  menuType: "default",
  theme: "lime",
  gray: "zinc",
  fonts: "default",
};

export default config;
