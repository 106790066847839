const Footer = () => {
  return (
    <footer className="mt-auto">
      <div className="footer">
        <span className="uppercase">&copy; 2023 ETHERIX TECH</span>
        <nav>
          <a href="mailto:Yeti Themes<info@yetithemes.net>?subject=Support">
            Support
          </a>
          <span className="divider">|</span>
          <a
            href="http://yeti.yetithemes.net/docs"
            target="_blank"
            rel="noreferrer"
          >
            Docs
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
