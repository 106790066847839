import Footer from "partials/Footer";

import Breadcrumb, { BreadcrumbItem } from "components/Breadcrumb";

//import { Calendar } from '../../../../src/fullcal/fullcalendar'

function Calendar() {
  return (
    <main className="workspace">
      {/* Breadcrumb */}
      <section className="breadcrumb">
        <Breadcrumb title="Calendar">
          <BreadcrumbItem link="#no-link">Pages</BreadcrumbItem>
          <BreadcrumbItem>Calendar</BreadcrumbItem>
        </Breadcrumb>
      </section>
      {/* Calendar */}
      <div>This is the placeholder for the Calendar module</div>
      
      <Footer />
    </main>
    
  );
}

export default Calendar;
